import React from "react"

const Social = () => {
var d = new Date();
var n = d.getFullYear();

  return (
	<section id="footer">
<ul className="icons">
									<li><a href="https://twitter.com/taumeson" className="icon brands fa-twitter"><span className="label">Twitter</span></a></li>
									<li><a href="https://www.facebook.com/taumeson" className="icon brands fa-facebook-f"><span className="label">Facebook</span></a></li>
									<li><a href="https://www.linkedin.com/in/nathanielengelsen/" className="icon brands fa-linkedin"><span className="label">LinkedIn</span></a></li>
								</ul>
								<p className="copyright">© {n} nerdleader</p>
	</section>
	)
}

export default Social

