import React from "react"  
import { useStaticQuery, Link, graphql } from "gatsby"

import ReactMarkdown from "react-markdown"  
import Moment from "react-moment"

import { FacebookShareButton,LinkedinShareButton,TwitterShareButton,RedditShareButton,WhatsappShareButton } from "react-share"
import { FacebookIcon, LinkedinIcon, TwitterIcon, RedditIcon, WhatsappIcon } from "react-share"

import Layout from "./layout"
import Social from "./social"
import Fixedimage from "./fixedimage"
import SEO from "./seo"

function Post({pageContext}) {

const data = useStaticQuery( graphql`  
  query {
allStrapiPosts {
    edges {
      node {
      strapiId
      title
        intro
        slug
      content
      publish_date
      category {
        name
        id
        }
        image: hero_image {
      childImageSharp {
        resize(width: 1200) {
          src
          height
          width
        }
      }
    }
      authors {
        first_name
        last_name
      }
      }
      next {
	title
        slug
      }
      previous {
	title
        slug
      }
    }
  }
 site {
    siteMetadata {
      title
      siteUrl
      description
      author
    }
  }
  }
`)

  const site = data.site
  const slug = pageContext.slug;

  const postEdge = data.allStrapiPosts.edges.find(function(edge) {
	return edge.node.slug === slug;
  })

  const prev = postEdge.previous;
//  const prevUrl = `${site.siteMetadata.siteUrl}/post/${prev.slug}`

  const next = postEdge.next;
  //const nextUrl = `${site.siteMetadata.siteUrl}/post/${next.slug}`

  const post = postEdge.node;

  const thisurl = `${site.siteMetadata.siteUrl}/post/${post.slug}`
  return (
    <Layout>
      <SEO 
	title={post.title}
	description={post.intro}
	image={post.image.childImageSharp.resize}
	pathname={`/post/${post.slug}`}
	isBlogPost={true}
	 />
      <div id="wrapper">
	<div id="main">
	  <article className="post">
	    <header>
		<div className="title">
		  <h2>{post.title}</h2>
		  <p>{post.intro}</p>
		</div>
	    <div className="meta">
		<Moment format="MMM Do YYYY" className="published">{post.publish_date}</Moment>
          {post.authors.map((author, i) => {
            return (
                <span className="author" key={author.first_name}><span className="name">{author.first_name} {author.last_name}</span><Fixedimage alt="Author avatar" filename="avatar.jpg" /></span>
            )
          })}
	    </div>
	    </header>
	    <span className="image featured"><img src={post.image.childImageSharp.resize.src} alt=""></img></span>
	    <div id="content">
		<ReactMarkdown source={post.content} />
	    </div>
	    <footer>
		<ul className="stats">
		  <li key="stats_category"><Link to={`/category/${post.category.id}`}>{post.category.name}</Link></li>
		  <li key="stats_social"> 
			<FacebookShareButton url={thisurl}><FacebookIcon size={32} round={true} /></FacebookShareButton>
			<LinkedinShareButton url={thisurl} summary={post.intro}><LinkedinIcon size={32} round={true} /></LinkedinShareButton>
			<TwitterShareButton title={post.title} url={thisurl}><TwitterIcon size={32} round={true} /></TwitterShareButton>
			<RedditShareButton title={post.title} url={thisurl}><RedditIcon size={32} round={true} /></RedditShareButton>
			<WhatsappShareButton title={post.title} url={thisurl}><WhatsappIcon size={32} round={true} /></WhatsappShareButton>

		</li>
		</ul>
	    </footer>
            <footer>
		<ul className="stats">
		{prev &&
			<li key="nav_prev"><span>Previous: <Link to={`/post/${prev?.slug}`}>{prev?.title}</Link></span></li>
			}
		{next &&
			<li key="nav_next"><span>Next: <Link to={`/post/${next?.slug}`}>{next?.title}</Link></span></li>
			}
		</ul>
	    </footer>
	  </article>
	</div>
	<Social />
     </div>
    </Layout>
  )
}

export default Post
